import utils from '../../utils';
import { getPosition, echartSetOption, tooltipFormatter } from './echarts-utils';

/* -------------------------------------------------------------------------- */
/*                                Outbound Calls Overview                          */
/* -------------------------------------------------------------------------- */

const outboundCallsOverviewInit = () => {
  const $outboundCallsOverview = document.querySelector('.echart-outbound-calls-overview');

  if ($outboundCallsOverview ) {
    const userOptions = utils.getData($outboundCallsOverview , 'options');
    const chart = window.echarts.init($outboundCallsOverview );

    // const tooltipFormatter = params => {
    //   let tooltipItem = ``
    //   params.forEach(el => {
    //     tooltipItem = tooltipItem +`<div class='ms-1'>
    //       <h6 class="fs--1 text-700"><span class="fas fa-circle me-2" style="color:${
    //         el.color}"></span>
    //         ${el.seriesName} : ${el.value}
    //       </h6>
    //     </div>`
    //   });
    //   return `<div>
    //             <p class='mb-2 text-600'>${window
    //               .dayjs(params[0].axisValue)
    //               .format('MMM DD, YYYY')}</p>
    //             ${tooltipItem}
    //           </div>`;
    // };

    const getDefaultOptions = () => ({
      color: [
        utils.getColors().primary,
        utils.rgbaColor(utils.getColors().primary, 0.8),
        utils.rgbaColor(utils.getColors().primary, 0.6),
        utils.rgbaColor(utils.getColors().primary, 0.4),
        utils.rgbaColor(utils.getColors().primary, 0.2)
      ],
      legend: {
        data: ['Blocked', 'No Answer', 'Answered'],
        left: 5,
        // bottom: 10,
        itemWidth: 10,
        itemHeight: 10,
        borderRadius: 0,
        icon: 'circle',
        inactiveColor: utils.getGrays()['400'],
        textStyle: { color: utils.getGrays()['700'] },
        itemGap: 20
      },
      xAxis: {
        type: 'category',
        data: utils.getPastDates(7).map(date => window.dayjs(date).format('DD MMM, YYYY')),
        axisLine: {
          show: false
        },
        splitLine: {
          lineStyle: {
            color: utils.getGrays()['200']
          }
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: utils.getGrays()['600'],
          formatter: value => window.dayjs(value).format('ddd')
        }
      },
      yAxis: {
        type: 'value',
        position: 'right',
        splitLine: {
          lineStyle: {
            color: utils.getGrays()['200']
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: true,
          color: utils.getGrays()['600'],
          margin: 15
        }
      },
      tooltip: {
        trigger: 'axis',
        padding: [7, 10],
        axisPointer: {
          type: 'none'
        },
        backgroundColor: utils.getGrays()['100'],
        borderColor: utils.getGrays()['300'],
        textStyle: { color: utils.getColors().dark },
        borderWidth: 1,
        transitionDuration: 0,
        position(pos, params, dom, rect, size) {
          return getPosition(pos, params, dom, rect, size);
        },
        formatter: tooltipFormatter
      },

      series: [
        {
          name: 'Blocked',
          type: 'bar',
          stack: 'total',
          data: [42, 0, 0, 56, 32, 16, 0]
        },
        {
          name: 'No Answer',
          type: 'bar',
          stack: 'total',
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: 'Answered',
          type: 'bar',
          stack: 'total',
          data: [223, 322, 199, 235, 255, 187, 222],
          itemStyle: {
            barBorderRadius: [5, 5, 0, 0]
          }
        }
      ],

      grid: {
        right: '50px',
        left: '0px',
        bottom: '10%',
        top: '15%'
      }
    });

    echartSetOption(chart, userOptions, getDefaultOptions);
  }
};

export default outboundCallsOverviewInit;
