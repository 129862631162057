import utils from "../../utils";
import { getPosition, echartSetOption } from "./echarts-utils";

/* -------------------------------------------------------------------------- */
/*                                Branded Performance                               */
/* -------------------------------------------------------------------------- */

const brandedPerformanceInit = () => {
  const ECHART_BAR_TOP_PRODUCTS = ".echart-branded-performance";
  const $echartBarTopProducts = document.querySelector(ECHART_BAR_TOP_PRODUCTS);

  if ($echartBarTopProducts) {
    const data = [
      ["product", "Branded", "Non-branded"],
      ["Sat", 50, 13],
      ["Sun", 80, 21],
      ["Mon", 43, 16],
      ["Tues", 83, 12],
      ["Wed", 86, 17],
      ["Thurs", 72, 18],
      ["Fri", 80, 22],
      
    ];
    const userOptions = utils.getData($echartBarTopProducts, "options");
    const chart = window.echarts.init($echartBarTopProducts);

    const getDefaultOptions = () => ({
      color: [utils.getColors().primary, utils.getGrays()["300"]],
      dataset: { source: data },
      tooltip: {
        trigger: "item",
        padding: [7, 10],
        backgroundColor: utils.getGrays()['100'],
        borderColor: utils.getGrays()["300"],
        textStyle: { color: utils.getColors().dark },
        borderWidth: 1,
        transitionDuration: 0,
        position(pos, params, dom, rect, size) {
          return getPosition(pos, params, dom, rect, size);
        },
        formatter: function (params) {
          return `<div class="font-weight-semi-bold">${
            params.seriesName
          }</div><div class="fs--1 text-600"><strong>${params.name}:</strong> ${
            params.value[params.componentIndex + 1]
          }</div>`;
        },
      },
      legend: {
        data: ["Branded", "Non-branded"],
        left: "left",
        itemWidth: 10,
        itemHeight: 10,
        borderRadius: 0,
        icon: "circle",
        inactiveColor: utils.getGrays()["400"],
        textStyle: { color: utils.getGrays()["700"] },
      },
      xAxis: {
        type: "category",
        axisLabel: { color: utils.getGrays()["400"] },
        axisLine: {
          lineStyle: {
            color: utils.getGrays()["300"],
            type: "dashed",
          },
        },
        axisTick: false,
        boundaryGap: true,
      },
      yAxis: {
        axisPointer: { type: "none" },
        axisTick: "none",
        splitLine: {
          lineStyle: {
            color: utils.getGrays()["300"],
            type: "dashed",
          },
        },
        axisLine: { show: false },
        axisLabel: { color: utils.getGrays()["400"] },
      },
      series: [
        {
          type: "bar",
          barWidth: "10px",
          barGap: "30%",
          label: { normal: { show: false } },
          z: 10,
          itemStyle: {
            normal: {
              barBorderRadius: [10, 10, 0, 0],
              color: utils.getColors().primary,
            },
          },
        },
        {
          type: "bar",
          barWidth: "10px",
          barGap: "30%",
          label: { normal: { show: false } },
          itemStyle: {
            normal: {
              barBorderRadius: [4, 4, 0, 0],
              color: utils.getGrays()[300],
            },
          },
        },
      ],
      grid: { right: "0", left: "30px", bottom: "10%", top: "20%" },
    });

    echartSetOption(chart, userOptions, getDefaultOptions);
  }
};

export default brandedPerformanceInit;
