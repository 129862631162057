import utils from "../../utils";
import { getPosition, echartSetOption } from "./echarts-utils";

/* -------------------------------------------------------------------------- */
/*                                Market Share                                */
/* -------------------------------------------------------------------------- */

const allOutboundCallsInit = () => {
  const ECHART_ALL_OUTBOUND_CALLS = ".echart-all-outbound-calls";
  const $echartAllOutboundCalls = document.querySelector(ECHART_ALL_OUTBOUND_CALLS);
  - console.log($echartAllOutboundCalls)
  if ($echartAllOutboundCalls) {
    const userOptions = utils.getData($echartAllOutboundCalls, "options");
    const chart = window.echarts.init($echartAllOutboundCalls);
    
    const colorOne = 'hsla(210, 34%, 52%, 1.0)'; // answered - blue
    const colorTwo = 'hsla(210, 34%, 52%, .8)'; // unanswered - gold
    const colorThree = 'hsla(210, 34%, 52%, .4)'; // blocked - red

    const getDefaultOptions = () => ({
      color: [
        colorOne,
        colorTwo,
        colorThree,
        //utils.getColors().danger
        //"#233343", // unanswered
        //utils.getColors().warning, // blocked
      ],
      tooltip: {
        trigger: "item",
        padding: [7, 10],
        backgroundColor: utils.getGrays()['100'],
        borderColor: utils.getGrays()["300"],
        textStyle: { color: utils.getColors().dark },
        borderWidth: 1,
        transitionDuration: 0,
        formatter: function (params) {
          return `<strong>${params.data.name}:</strong> ${params.percent}%`;
        },
      },
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      legend: { show: false },
      series: [
        {
          type: "pie",
          radius: ["100%", "87%"],
          avoidLabelOverlap: false,
          hoverAnimation: false,
          itemStyle: {
            borderWidth: 2,
            borderColor: utils.getColor('card-bg'),
          },
          label: {
            normal: {
              show: false,
              position: "center",
              textStyle: {
                fontSize: "20",
                fontWeight: "500",
                color: utils.getGrays()["700"],
              },
            },
            emphasis: {
              show: false,
            },
          },
          labelLine: { normal: { show: false } },
          data: [
            { value: 5300000, name: "Answered" },
            { value: 1900000, name: "Unanswered" },
            { value: 200000, name: "Blocked" },
          ],
        },
      ],
    });

    echartSetOption(chart, userOptions, getDefaultOptions);
  }
};

export default allOutboundCallsInit;
