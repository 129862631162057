import utils from "../../utils";
import { getPosition, echartSetOption } from "./echarts-utils";

/* -------------------------------------------------------------------------- */
/*                                Market Share                                */
/* -------------------------------------------------------------------------- */

const processedCallsInit = () => {
  const ECHART_PROCESSED_CALLS = ".echart-processed-calls";
  const $echartProcessedCalls = document.querySelector(ECHART_PROCESSED_CALLS);
  if ($echartProcessedCalls) {
    const userOptions = utils.getData($echartProcessedCalls, "options");
    const chart = window.echarts.init($echartProcessedCalls);
    
    const colorOne = 'hsla(210, 34%, 52%, 1.0)'; // answered - blue
    const colorTwo = 'hsla(210, 34%, 52%, .8)'; // unanswered - gold
    const colorThree = 'hsla(210, 34%, 52%, .4)'; // blocked - red

    const getDefaultOptions = () => ({
      color: [
        colorOne,
        colorTwo,
        colorThree,
        //utils.getColors().danger
        //"#233343", // unanswered
        //utils.getColors().warning, // blocked
      ],
      tooltip: {
        trigger: "item",
        padding: [7, 10],
        backgroundColor: utils.getGrays()['100'],
        borderColor: utils.getGrays()["300"],
        textStyle: { color: utils.getColors().dark },
        borderWidth: 1,
        transitionDuration: 0,
        formatter: function (params) {
          return `<strong>${params.data.name}:</strong> ${params.percent}%`;
        },
      },
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      legend: { show: false },
      series: [
        {
          type: "pie",
          radius: ["100%", "87%"],
          avoidLabelOverlap: false,
          hoverAnimation: false,
          itemStyle: {
            borderWidth: 2,
            borderColor: utils.getColor('card-bg'),
          },
          label: {
            normal: {
              show: false,
              position: "center",
              textStyle: {
                fontSize: "20",
                fontWeight: "500",
                color: utils.getGrays()["700"],
              },
            },
            emphasis: {
              show: false,
            },
          },
          labelLine: { normal: { show: false } },
          data: [
            { value: 3421000, name: "AT&T" },
            { value: 2570000, name: "Sprint / T-Mobile" },
            { value: 4009000, name: "Verizon" },
            //{ value: 200000, name: "Unprocessed" },
          ],
        },
      ],
    });

    echartSetOption(chart, userOptions, getDefaultOptions);
  }
};

export default processedCallsInit;
